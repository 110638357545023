import { IPayoutStructure } from "../../db/models"
import fetchPartnerDashboardService from "../../service/fetchPartnerDashboardService"

async function getPayoutStructureList(licenseName: string) {
  const res = await fetchPartnerDashboardService(
    "GET",
    null,
    `general/license/getAllPartnerPaymentStructures/${licenseName}`
  )

  return res.partnerPaymentStructures as IPayoutStructure[]
}

export { getPayoutStructureList }
