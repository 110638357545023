import { Button } from "@/components/ui/Button"
import { Command, CommandInput } from "@/components/ui/Command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/Popover"
import { cn } from "@/lib/utils"
import { Check, ChevronsUpDown } from "lucide-react"
import * as React from "react"

interface ComboboxOption {
  value: string
  label: React.ReactNode
}

interface ComboboxProps {
  options: ComboboxOption[]
  value: string
  setValue: (value: string) => void
  onSearchValueChange: (value: string) => void
  customTrigger?: React.ReactNode
}
export function Combobox({
  options,
  value,
  setValue,
  onSearchValueChange,
  customTrigger,
}: ComboboxProps) {
  const [open, setOpen] = React.useState(false)
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          role="combobox"
          aria-expanded={open}
          className="w-[30rem] justify-between"
        >
          {customTrigger ? (
            customTrigger
          ) : (
            <>
              {value
                ? options.find((license) => license.value === value)?.label
                : "Select license..."}
              <ChevronsUpDown className="opacity-50" />
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[30rem] p-0">
        <Command>
          <CommandInput
            placeholder="Search license..."
            onValueChange={(value) => onSearchValueChange(value)}
          />
          {/* <CommandList>
            <CommandEmpty>
              No license found.{options.length} found.
            </CommandEmpty>
            <CommandGroup>
              {options.map((license) => (
                <CommandItem
                  key={license.value}
                  value={license.value}
                  onSelect={(currentValue) => {
                    setValue(currentValue === value ? "" : currentValue)
                    setOpen(false)
                  }}
                >
                  {license.label}
                  <Check
                    className={cn(
                      "ml-auto",
                      value === license.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList> */}
          <div className="max-h-96 overflow-scroll scrollbar-none">
            {options.map((license) => (
              <div key={license.value} className="px-2 py-1 flex flex-col">
                <button
                  className={cn(
                    "relative flex cursor-default gap-2 select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none data-[disabled=true]:pointer-events-none data-[selected='true']:bg-accent data-[selected=true]:text-accent-foreground data-[disabled=true]:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
                    "hover:bg-gray-100 transition-all duration-100 ease-in-out"
                  )}
                  onClick={() => {
                    setValue(license.value)
                    setOpen(false)
                  }}
                >
                  {license.label}
                  <Check
                    className={cn(
                      "ml-auto",
                      value === license.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                </button>
              </div>
            ))}
          </div>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
