import { useLicenseContext } from "../context/licenseContext"
import { ILicense } from "../db/models"
import { Combobox } from "./ui/Combobox"
import { cn } from "@/lib/utils"
import { ChevronDownIcon, KeyIcon } from "@heroicons/react/outline"
import { useEffect, useState } from "react"

export function LicenseSelector({
  placement = "left",
}: {
  placement?: "left" | "right"
}) {
  const { licenses, selectedLicense, setSelectedLicense } = useLicenseContext()
  const [filteredLicenses, setFilteredLicenses] = useState<ILicense[]>([])

  useEffect(() => {
    if (licenses) {
      setFilteredLicenses(licenses)
    }
  }, [licenses])

  const handleSelect = (selection: ILicense) => {
    setSelectedLicense(selection)
    setFilteredLicenses(licenses)
    // router.replace(router.asPath)
  }

  return (
    <div className="flex items-end text-gray-500 text-sm font-medium">
      {!selectedLicense ? (
        "..."
      ) : (
        <div
          className={cn(
            placement === "left" ? "" : "w-48 absolute right-0 top-0"
          )}
        >
          <Combobox
            options={filteredLicenses.map((license) => ({
              value: license.id,
              label: (
                <span
                  className={`block truncate ${
                    selectedLicense?.id === license.id
                      ? "font-semibold"
                      : "font-normal"
                  }`}
                >
                  {license.name}{" "}
                  <span className="ml-2 text-gray-400 text-xs">
                    {license.id}
                  </span>
                </span>
              ),
            }))}
            value={selectedLicense.id}
            setValue={(value) => {
              const license = licenses.find((license) => license.id === value)
              if (license) {
                handleSelect(license)
              }
            }}
            onSearchValueChange={(value) => {
              setFilteredLicenses(
                licenses.filter((license) =>
                  license.name.toLowerCase().includes(value.toLowerCase())
                )
              )
            }}
            customTrigger={
              <div className="flex items-center gap-1 font-semibold text-alt group">
                {process.env.NODE_ENV === "development" ? (
                  <span className="uppercase font-semibold tracking-wide inline-flex items-center rounded-full bg-sky-50 px-2 py-1 text-[10px] text-sky-800 ring-1 ring-inset ring-sky-600/20">
                    <KeyIcon
                      className="flex-shrink-0 mr-1.5 h-4 w-4 text-sky-800"
                      aria-hidden="true"
                    />
                    UAT
                  </span>
                ) : process.env.NEXT_PUBLIC_VERCEL_ENV !== "production" ? (
                  <span className="uppercase font-semibold tracking-wide inline-flex items-center rounded-full bg-yellow-50 px-2 py-1 text-[10px] text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                    <KeyIcon
                      className="flex-shrink-0 mr-1.5 h-4 w-4 text-yellow-800"
                      aria-hidden="true"
                    />
                    STAGING
                  </span>
                ) : (
                  <span className="uppercase font-semibold tracking-wide inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-[10px] text-green-800 ring-1 ring-inset ring-green-600/20">
                    <KeyIcon
                      className="flex-shrink-0 mr-1.5 h-4 w-4 text-green-800"
                      aria-hidden="true"
                    />
                    PRODUCTION
                  </span>
                )}
                <span className="ml-2">{selectedLicense?.name}</span>

                <span className="pointer-events-none">
                  <ChevronDownIcon
                    className="h-4 w-4 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </span>
              </div>
            }
          />
        </div>
      )}
    </div>
  )
}
