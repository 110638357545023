/* eslint-disable @typescript-eslint/ban-ts-comment */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Error as ErrorPage, Layout } from "../components"
import Chart from "../components/Chart"
import InfoDialog from "../components/Dialog/Info"
import { LicenseSelector } from "../components/LicenseSelector"
import TooltipComponent from "../components/TooltipComponent"
import {
  getBusinessesWithIncompleteApplications,
  getPoliciesForPartner,
  getQualifiedLeads,
  getTotalPartnerRevShare,
  getTotalPremiumForDates,
} from "../components/dashboard/dashboard.service"
import { useAuth } from "../context/authContext"
import { useLicenseContext } from "../context/licenseContext"
import { IPayoutStructure, IPolicy } from "../db/models"
import { allPolicyCodes, dev_log } from "../utilities"
import { MONTHS, QUARTERS } from "../utils/constants"
import { getMonthDates } from "../utils/getMonthDates"
import { getQuarterDates, StartEndDates } from "../utils/getQuarterDates"
import Custom404 from "./404"
import { getWhitelistedIPs } from "@/constants/whitelistedIPs"
import { shouldHideRevenueChartForDateRange } from "@/data/nerdwallet-temp-data"
import { getPayoutStructureList } from "@components/payout-structure/payout-structure.service"
import * as Sentry from "@sentry/nextjs"
import {
  BarList,
  Card,
  Flex,
  Grid,
  Metric,
  Select,
  SelectItem,
  Text,
  Title,
} from "@tremor/react"
import dayjs from "dayjs"
import quarterOfYear from "dayjs/plugin/quarterOfYear"
import { GetServerSidePropsContext } from "next/types"
import numeral from "numeral"
// import { useRouter } from "next/router"
import { useEffect, useMemo, useRef, useState } from "react"

dayjs.extend(quarterOfYear)

const superUserEmails = [
  "super@coverdash.com",
  "super.staging@coverdash.com",
  "super.uat@coverdash.com",
]

const dataFormatter = (number: number) =>
  Intl.NumberFormat("us").format(number).toString()

const getGreeting = () => {
  const date = new Date()
  const currentTime = date.getHours()

  if (currentTime >= 0 && currentTime <= 12) {
    return "Good Morning"
  } else if (currentTime > 12 && currentTime <= 18) {
    return "Good Afternoon"
  } else {
    return "Good Evening"
  }
}

interface StatCategory {
  title: string
  metric: string | null
  metricPrev?: string
  tooltip?: string
}

function getYearsFrom2022(): string[] {
  const currentYear = new Date().getFullYear()
  return Array.from({ length: currentYear - 2021 }, (_, i) => String(2022 + i))
}

interface HomePageProps {
  isIPWhitelisted: boolean
}

export default function HomePage({ isIPWhitelisted }: HomePageProps) {
  const greetingText = getGreeting()
  // const router = useRouter()
  const [hasError, setHasError] = useState(false)
  const { user } = useAuth()
  const { selectedLicense, getSelectedLicense } = useLicenseContext()
  const [payoutStructure, setPayoutStructure] = useState<IPayoutStructure[]>([])

  const [loading, setLoading] = useState(true)

  const [selectedQuarterOrMonth, setSelectedQuarterOrMonth] =
    useState<string>("Q1")
  const [selectedYear, setSelectedYear] = useState<string>(
    dayjs().year().toString()
  )
  const currentDateRange = useMemo(() => {
    let dates: StartEndDates = {
      startDate: "",
      endDate: "",
    }
    if (QUARTERS.includes(selectedQuarterOrMonth)) {
      dates = getQuarterDates(selectedQuarterOrMonth, selectedYear)
    } else {
      dates = getMonthDates(selectedQuarterOrMonth, selectedYear)
    }
    return [dates.startDate, dates.endDate]
  }, [selectedQuarterOrMonth, selectedYear, getQuarterDates, getMonthDates])

  const [years, setYears] = useState<string[]>([
    new Date().getFullYear().toString(),
  ])

  // useEffect(() => {
  //   console.table({
  //     year: selectedYear,
  //     quarter: selectedQuarterOrMonth,
  //   })
  // }, [selectedQuarterOrMonth, selectedYear])

  useEffect(() => {
    // Default quarter to current quarter
    setSelectedQuarterOrMonth(`Q${dayjs(new Date()).quarter()}`)

    // Default year to current year
    setSelectedYear(new Date().getFullYear().toString())

    // // Set years to all years from 2022 until today
    setYears(getYearsFrom2022())
  }, [])

  const [categories, setCategories] = useState<StatCategory[]>([
    // {
    //   title: "Total Applications Created",
    //   metric: null,
    //   // metricPrev: "$ 9,456",
    //   tooltip:
    //     "The total number of applications started in the selected quarter.",
    // },
    // {
    //   title: "Applications Completed",
    //   metric: null,
    //   // metricPrev: "$ 45,564",
    // },
    // {
    //   title: "Total Premium",
    //   metric: null,
    //   tooltip: "The total premium sold in the selected quarter.",
    // },
    {
      title: "Total Partner Revenue Share",
      metric: null,
      tooltip: "The total amount of commission earned in the selected quarter.",
    },
  ])
  const [chartData, setChartData] = useState([])
  const [totalCommissionEarned, setTotalCommissionEarned] = useState(0)

  const [
    businessesWithIncompleteApplications,
    setBusinessesWithIncompleteApplications,
  ] = useState<number>(0)
  const [
    industryFilter1ListIncompleteApps,
    setIndustryFilter1ListIncompleteApps,
  ] = useState<
    {
      name: string
      total: number
    }[]
  >([])
  const [
    businessesWithAppsSubmittedForQuotes,
    setBusinessesWithAppsSubmittedForQuotes,
  ] = useState<number>(0)
  const [
    industryFilter1ListSubmittedApps,
    setIndustryFilter1ListSubmittedApps,
  ] = useState<
    {
      name: string
      total: number
    }[]
  >([])
  const [policies, setPolicies] = useState<IPolicy[]>([])

  const fetchData = async (
    startDate = dayjs().startOf("year").toDate(),
    endDate = new Date()
  ) => {
    try {
      setLoading(true)
      const _selectedLicense = selectedLicense || (await getSelectedLicense())
      dev_log("_selectedLicense:", _selectedLicense)
      // dev_log(licenses)

      dev_log("superUserEmails", superUserEmails)
      dev_log("user?.email", user?.email)
      dev_log("isIPWhitelisted", isIPWhitelisted)
      /**
       * If super user is logged in and IP is not whitelisted, throw an error
       */
      if (superUserEmails.includes(user?.email) && !isIPWhitelisted) {
        throw new Error("Super User requires whitelisted IP")
      }

      if (_selectedLicense && user?.id) {
        const _payoutStructure = await getPayoutStructureList(
          _selectedLicense.name
        )
        setPayoutStructure(_payoutStructure)
        const _businessesWithIncompleteApplications =
          await getBusinessesWithIncompleteApplications(
            _selectedLicense.id,
            currentDateRange[0],
            currentDateRange[1]
          )

        setIndustryFilter1ListIncompleteApps(
          _businessesWithIncompleteApplications.filter1s
        )

        setBusinessesWithIncompleteApplications(
          _businessesWithIncompleteApplications.total
        )

        const _qualifiedLeads = await getQualifiedLeads(
          _selectedLicense.id,
          currentDateRange[0],
          currentDateRange[1]
        )

        setIndustryFilter1ListSubmittedApps(_qualifiedLeads.filter1s)

        setBusinessesWithAppsSubmittedForQuotes(
          _qualifiedLeads.totalQualifiedLeads
        )

        const policiesRes = await getPoliciesForPartner(
          _selectedLicense.id,
          `${dayjs(startDate).utc().format("YYYY-MM-DD")}T00:00:00`,
          `${dayjs(endDate).endOf("day").utc().format("YYYY-MM-DD")}T23:59:59`
        )
        const _policies = policiesRes?.policies || []
        setPolicies(_policies)

        // setTotalAmountOwedFromEndorsements(
        //   policiesRes?.endorsements[selectedLicense?.id] || 0
        // )
      }
    } catch (error) {
      setHasError(true)
      console.error(error)
      Sentry.captureException(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    dev_log("~~ Fetch Data ~~")
    fetchData(new Date(currentDateRange[0]), new Date(currentDateRange[1]))
    // else fetchData()
  }, [
    user,
    selectedLicense,
    currentDateRange,
    selectedQuarterOrMonth,
    selectedYear,
  ])

  const [policiesBoundBarData, setPoliciesBoundBarData] = useState([])
  const [policiesByIndustryBarData, setPoliciesByIndustryBarData] = useState([])
  function filterData() {
    const _policiesBoundBarData = []
    const _policiesByIndustryBarData = []

    const uniquePolicies = Array.from(
      new Set(policies.map((p) => p.policyType))
    )

    uniquePolicies.forEach((policyType) => {
      _policiesBoundBarData.push({
        name: allPolicyCodes[policyType]?.title || policyType,
        value: policies.filter((p) => p.policyType === policyType).length,
      })
    })

    setPoliciesBoundBarData(_policiesBoundBarData)

    // Get policies by industry type
    const policiesByIndustry: { [industry: string]: number } = {}
    policies.forEach((p) => {
      if (!policiesByIndustry[p.businessClassificationDisplay]) {
        policiesByIndustry[p.businessClassificationDisplay] = 1
      }
      //
      else {
        policiesByIndustry[p.businessClassificationDisplay] =
          policiesByIndustry[p.businessClassificationDisplay] + 1
      }
    })

    dev_log("policiesByIndustry", policiesByIndustry)

    Object.entries(policiesByIndustry).forEach(([industry, count]) => {
      _policiesByIndustryBarData.push({
        name: industry,
        value: count,
      })
    })
    _policiesByIndustryBarData.sort((a, b) => b.value - a.value)
    setPoliciesByIndustryBarData(_policiesByIndustryBarData)
  }

  useEffect(() => {
    dev_log("~~ Filter Data ~~")
    if (selectedLicense) {
      filterData()
    }
  }, [policies])

  async function refreshStats() {
    if (selectedLicense) {
      dev_log("~~ Refresh Stats ~~")
      const partnerRevShareRes = await getTotalPartnerRevShare(
        selectedLicense.id,
        currentDateRange[0],
        currentDateRange[1]
      )

      const adjustedPartnerRevShareData = JSON.parse(
        JSON.stringify(partnerRevShareRes)
      ) as { [date: string]: number }
      delete adjustedPartnerRevShareData.totalPartnerRevShare
      dev_log("adjustedPartnerRevShareData", adjustedPartnerRevShareData)
      const sortedRevenueData = Object.entries(adjustedPartnerRevShareData)
        .map(([date, revenue]) => ({
          date,
          "Partner Revenue Share": revenue,
        }))
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

      dev_log("sortedRevenueData", sortedRevenueData)
      setChartData(sortedRevenueData)

      setTotalCommissionEarned(partnerRevShareRes.totalPartnerRevShare)
      // const _totalCommissionEarned = policyStats.reduce((acc, curr) => {
      //   return acc + curr["Partner Revenue Share"]
      // }, 0)
      // setTotalCommissionEarned(_totalCommissionEarned)
      // dev_log("applicationsFiltered", applicationsFiltered)
      // dev_log("applicationsNoPolicySelected", applicationsNoPolicySelected)

      if (selectedLicense?.name === "Bluevine") {
        const totalPremium = await getTotalPremiumForDates(
          currentDateRange[0],
          currentDateRange[1],
          selectedLicense.id
        )
        setCategories([
          // {
          //   title: "Total Applications Created",
          //   metric: dataFormatter(
          //     applicationsFiltered.length +
          //     applicationsNoPolicySelected.length +
          //     applicationsNoQuotesReturned.length
          //   ),
          //   // metricPrev: "$ 9,456",
          //   tooltip:
          //     "The total number of applications created in the selected quarter.",
          // },
          // {
          //   title: "Applications Completed",
          //   metric: dataFormatter(
          //     applicationsFiltered.filter((a) => a.status === "COMPLETED").length
          //   ),
          // },
          {
            title: "Total Premium",
            metric: numeral(totalPremium).format("$0,0.00"),
            tooltip: "The total premium sold in the selected quarter.",
          },
          {
            title: "Total Partner Revenue Share",
            metric: numeral(partnerRevShareRes.totalPartnerRevShare).format(
              "$0,0.00"
            ),
            // numeral(
            //   _totalCommissionEarned + totalAmountOwedFromEndorsements
            // ).format("$0,0.00"),
            // metricPrev: "$ 9,456",
            tooltip: "The total commission earned in the selected quarter.",
          },
        ])
      }
      //
      else {
        setCategories([
          // {
          //   title: "Total Applications Created",
          //   metric: dataFormatter(
          //     applicationsFiltered.length +
          //     applicationsNoPolicySelected.length +
          //     applicationsNoQuotesReturned.length
          //   ),
          //   // metricPrev: "$ 9,456",
          //   tooltip:
          //     "The total number of applications created in the selected quarter.",
          // },
          // {
          //   title: "Applications Completed",
          //   metric: dataFormatter(
          //     applicationsFiltered.filter((a) => a.status === "COMPLETED").length
          //   ),
          // },
          {
            title: "Total Partner Revenue Share",
            metric: numeral(partnerRevShareRes.totalPartnerRevShare).format(
              "$0,0.00"
            ),
            // numeral(
            //   _totalCommissionEarned + totalAmountOwedFromEndorsements
            // ).format("$0,0.00"),
            // metricPrev: "$ 9,456",
            tooltip: "The total commission earned in the selected quarter.",
          },
        ])
      }
    }
  }

  function useDeepCompareEffect(callback, dependencies) {
    const currentDependenciesRef = useRef()

    if (
      JSON.stringify(currentDependenciesRef.current) !==
      JSON.stringify(dependencies)
    ) {
      currentDependenciesRef.current = dependencies
    }

    useEffect(callback, [currentDependenciesRef.current])
  }

  useDeepCompareEffect(() => {
    refreshStats()
  }, [selectedLicense, selectedQuarterOrMonth, selectedYear])

  const [mobileTooltipOpen, setMobileTooltipOpen] = useState(false)
  const [mobileTooltipTitle, setMobileTooltipTitle] = useState("")
  const [mobileTooltipBody, setMobileTooltipBody] = useState("")

  if (!user?.id) {
    return (
      <Layout>
        <></>
      </Layout>
    )
  }
  //
  /**
   * If super user is logged in and IP is not whitelisted, throw an error
   */
  if (superUserEmails.includes(user?.email) && !isIPWhitelisted) {
    return <Custom404 useLayout={false} />
  }

  return (
    <Layout>
      {hasError ? (
        <div className="m-8">
          <ErrorPage />
        </div>
      ) : (
        <div className="pb-8">
          <div className="bg-white shadow">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="py-6 flex flex-col space-y-3 lg:space-y-0 lg:flex-row items-center justify-between">
                <div className="flex-1 min-w-0">
                  <div className="flex items-center">
                    <div>
                      <div className="flex items-center">
                        <h1 className="ml-3 text-2xl font-extrabold leading-7 text-alt sm:leading-9 sm:truncate">
                          {user?.firstName ? (
                            <>
                              <span className="text-primary">
                                {greetingText}
                              </span>
                              , {user.firstName}
                            </>
                          ) : (
                            <span>Welcome Back</span>
                          )}
                        </h1>
                      </div>
                      <div className="mt-3 ml-2 flex">
                        <LicenseSelector />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="grid max-w-2xl grid-cols-2 gap-x-6 gap-y-8 sm:grid-cols-12 md:col-span-2">
                    {/* QUARTER/MONTH */}
                    <div className="sm:col-span-6">
                      <label className="text-sm text-gray-500">
                        Quarter/Month
                      </label>
                      <div className="mt-2">
                        <Selector
                          options={[...QUARTERS, ...MONTHS]}
                          selected={selectedQuarterOrMonth}
                          handleChange={setSelectedQuarterOrMonth}
                        />
                      </div>
                    </div>
                    {/* YEAR */}
                    <div className="sm:col-span-6">
                      <label className="text-sm text-gray-500">Year</label>
                      <div className="mt-2">
                        <Selector
                          options={years}
                          selected={selectedYear}
                          handleChange={setSelectedYear}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <InfoDialog
            open={mobileTooltipOpen}
            setOpen={setMobileTooltipOpen}
            title={mobileTooltipTitle}
            body={mobileTooltipBody}
          />
          <div className="mt-8">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div>
                <Grid className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-6">
                  {categories.map((item) => (
                    <Card key={item.title} className="ring-0 bg-white">
                      <Flex className="items-start">
                        <Text className="text-lg">{item.title}</Text>
                        <span className="ml-auto inline-flex">
                          <TooltipComponent
                            tooltipText={item.tooltip}
                            tooltipTitle={item.title}
                            setMobileTooltipOpen={setMobileTooltipOpen}
                            setMobileTooltipTitle={setMobileTooltipTitle}
                            setMobileTooltipBody={setMobileTooltipBody}
                          />
                        </span>
                      </Flex>
                      <Flex className="justify-start items-baseline space-x-3 truncate">
                        {!loading && item.metric ? (
                          <p className="text-gray-700 font-semibold text-2xl">
                            {item.metric}
                          </p>
                        ) : (
                          <div className="mt-4 animate-pulse flex space-x-4">
                            <div className="rounded-md bg-gray-300 h-4 w-16"></div>
                          </div>
                        )}
                        {/* <Text className="truncate">from {item.metricPrev}</Text> */}
                      </Flex>
                    </Card>
                  ))}
                </Grid>
                <Grid
                  numItemsSm={2}
                  numItemsLg={2}
                  className="gap-x-6 gap-y-6 mt-8"
                >
                  <Card className="ring-0 bg-white">
                    <Flex className="items-start">
                      <Title className="pb-7">
                        Businesses with Incomplete Applications
                      </Title>
                      <span className="ml-auto inline-flex">
                        <TooltipComponent
                          tooltipText={
                            "The total number of businesses that have created an application in the selected quarter, that is still in progress, and has not yet submitted a quote."
                          }
                          tooltipTitle={
                            "Businesses with incomplete applications"
                          }
                          setMobileTooltipOpen={setMobileTooltipOpen}
                          setMobileTooltipTitle={setMobileTooltipTitle}
                          setMobileTooltipBody={setMobileTooltipBody}
                        />
                      </span>
                    </Flex>
                    <Flex
                      justifyContent="start"
                      alignItems="baseline"
                      className="space-x-2"
                    >
                      {loading ? (
                        <div className="mt-4 animate-pulse flex space-x-4">
                          <div className="rounded-md bg-gray-300 h-4 w-16"></div>
                        </div>
                      ) : (
                        <Metric>{businessesWithIncompleteApplications}</Metric>
                      )}

                      {/* <Text>In progress</Text> */}
                    </Flex>
                    <Flex className="my-6">
                      <Text>Industry</Text>
                      <Text className="text-right">#</Text>
                    </Flex>
                    {loading ? (
                      <div className="mt-4 animate-pulse">
                        <div className="rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                      </div>
                    ) : businessesWithIncompleteApplications > 0 ? (
                      <BarList
                        data={industryFilter1ListIncompleteApps.map((item) => ({
                          name: item.name,
                          value: item.total,
                        }))}
                        valueFormatter={dataFormatter}
                        className="mt-2 scrollbar-none max-h-72 overflow-y-scroll"
                      />
                    ) : null}
                  </Card>
                  <Card className="ring-0 bg-white">
                    <Flex className="items-start">
                      <Title className="pb-7">
                        {payoutStructure[0]?.feeTrigger === "LEAD"
                          ? "Qualified Leads"
                          : "Businesses with applications submitted for quotes"}
                      </Title>
                      <span className="ml-auto inline-flex">
                        <TooltipComponent
                          tooltipText={
                            "The total number of businesses that have created an application in the selected quarter, that is filled out completely and that has been submitted for quotes."
                          }
                          tooltipTitle={
                            "Businesses with applications submitted for quotes"
                          }
                          setMobileTooltipOpen={setMobileTooltipOpen}
                          setMobileTooltipTitle={setMobileTooltipTitle}
                          setMobileTooltipBody={setMobileTooltipBody}
                        />
                      </span>
                    </Flex>
                    <Flex
                      justifyContent="start"
                      alignItems="baseline"
                      className="space-x-2"
                    >
                      {loading ? (
                        <div className="mt-4 animate-pulse flex space-x-4">
                          <div className="rounded-md bg-gray-300 h-4 w-16"></div>
                        </div>
                      ) : (
                        <Metric>{businessesWithAppsSubmittedForQuotes}</Metric>
                      )}
                      {/* <Text>Pending policy purchase</Text> */}
                    </Flex>
                    <Flex className="mt-6">
                      <Text>Industry</Text>
                      <Text className="text-right">#</Text>
                    </Flex>
                    {loading ? (
                      <div className="mt-4 animate-pulse">
                        <div className="rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                      </div>
                    ) : businessesWithAppsSubmittedForQuotes > 0 ? (
                      <BarList
                        data={industryFilter1ListSubmittedApps.map((item) => ({
                          name: item.name,
                          value: item.total,
                        }))}
                        valueFormatter={dataFormatter}
                        className="mt-2 scrollbar-none max-h-72 overflow-y-scroll"
                      />
                    ) : null}
                  </Card>
                  <Card className="ring-0 bg-white">
                    <Flex className="items-start">
                      <Title className="pb-7">Policies Bound</Title>
                      <span className="ml-auto inline-flex">
                        <TooltipComponent
                          tooltipText={
                            "The total number of applications, created in the selected quarter, that are completed and that have resulted in a bound policy."
                          }
                          tooltipTitle={"Policies Bound"}
                          setMobileTooltipOpen={setMobileTooltipOpen}
                          setMobileTooltipTitle={setMobileTooltipTitle}
                          setMobileTooltipBody={setMobileTooltipBody}
                        />
                      </span>
                    </Flex>
                    <Flex
                      justifyContent="start"
                      alignItems="baseline"
                      className="space-x-2"
                    >
                      {loading ? (
                        <div className="mt-4 animate-pulse flex space-x-4">
                          <div className="rounded-md bg-gray-300 h-4 w-16"></div>
                        </div>
                      ) : (
                        <Metric>
                          {policiesBoundBarData.reduce(
                            (acc, curr) => acc + curr.value,
                            0
                          )}
                        </Metric>
                      )}
                      <Text>
                        {policiesBoundBarData.reduce(
                          (acc, curr) => acc + curr.value,
                          0
                        ) > 1
                          ? "Policies"
                          : "Policy"}{" "}
                        bound
                      </Text>
                    </Flex>
                    <Flex className="mt-6">
                      <Text>Policy Type</Text>
                      <Text className="text-right">#</Text>
                    </Flex>
                    {loading ? (
                      <div className="mt-4 animate-pulse">
                        <div className="rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                        <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                      </div>
                    ) : (
                      <BarList
                        data={policiesBoundBarData}
                        valueFormatter={dataFormatter}
                        className="mt-2 scrollbar-none max-h-72 overflow-y-scroll"
                      />
                    )}
                  </Card>
                  {policiesByIndustryBarData.reduce(
                    (acc, curr) => acc + curr.value,
                    0
                  ) > 0 && (
                    <Card className="ring-0 bg-white">
                      <Flex className="items-start">
                        <Title className="pb-7">Policies by Industry</Title>
                        <span className="ml-auto inline-flex">
                          <TooltipComponent
                            tooltipText={
                              "All bound policies, categorized by industry type."
                            }
                            tooltipTitle={"Policies Bound"}
                            setMobileTooltipOpen={setMobileTooltipOpen}
                            setMobileTooltipTitle={setMobileTooltipTitle}
                            setMobileTooltipBody={setMobileTooltipBody}
                          />
                        </span>
                      </Flex>
                      <Flex
                        justifyContent="start"
                        alignItems="baseline"
                        className="space-x-2"
                      >
                        {loading ? (
                          <div className="mt-4 animate-pulse flex space-x-4">
                            <div className="rounded-md bg-gray-300 h-4 w-16"></div>
                          </div>
                        ) : (
                          <Metric>
                            {policiesByIndustryBarData.reduce(
                              (acc, curr) => acc + curr.value,
                              0
                            )}
                          </Metric>
                        )}
                        <Text>
                          {policiesByIndustryBarData.reduce(
                            (acc, curr) => acc + curr.value,
                            0
                          ) > 1
                            ? "Policies"
                            : "Policy"}{" "}
                          among {Object.keys(policiesByIndustryBarData).length}{" "}
                          industry type
                          {Object.keys(policiesByIndustryBarData).length > 1
                            ? "s"
                            : ""}
                        </Text>
                      </Flex>
                      <Flex className="mt-6">
                        <Text>Policy Type</Text>
                        <Text className="text-right">#</Text>
                      </Flex>
                      {loading ? (
                        <div className="mt-4 animate-pulse">
                          <div className="rounded-md bg-gray-300 h-8 w-full"></div>
                          <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                          <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                          <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                          <div className="mt-4 rounded-md bg-gray-300 h-8 w-full"></div>
                        </div>
                      ) : (
                        <BarList
                          data={policiesByIndustryBarData}
                          valueFormatter={dataFormatter}
                          className="mt-2 scrollbar-none max-h-72 overflow-y-scroll"
                        />
                      )}
                    </Card>
                  )}
                </Grid>
                {selectedLicense?.name === "NerdWallet" &&
                shouldHideRevenueChartForDateRange(
                  `${dayjs(currentDateRange[0]).utc().format("YYYY-MM-DD")}`,
                  `${dayjs(currentDateRange[1]).utc().format("YYYY-MM-DD")}`
                ) ? null : (
                  <Chart
                    title="Partner Revenue Share"
                    subtitle={`Total earned: ${numeral(
                      totalCommissionEarned
                    ).format("$0,0.00")}`}
                    data={chartData}
                    categories={["Partner Revenue Share"]}
                    index="date"
                    colors={["indigo"]}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}

const Selector = ({
  options,
  selected,
  handleChange,
}: {
  options: string[]
  selected: any
  handleChange: (selected: any) => void
}) => {
  return (
    <Select
      className={`block w-full sm:col-span-2 text-sm`}
      value={selected}
      onChange={handleChange}
      enableClear={false}
    >
      {options.map((option, index) => (
        <SelectItem key={index} value={option}>
          {option}
        </SelectItem>
      ))}
    </Select>
  )
}

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  const { req } = ctx
  const ip = req.headers["x-forwarded-for"] || req.socket.remoteAddress
  console.log("IP: ", ip)

  const whitelistedIPs = await getWhitelistedIPs()
  return {
    props: {
      isIPWhitelisted: whitelistedIPs.includes(ip as string),
    },
  }
}
